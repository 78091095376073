:root {
  font-size: 0.875rem;
}

body,
html {
  margin: 0;
  padding: 0;
}

* {
  box-sizing: border-box;
  font-family: "Aribau" !important;
}

@font-face {
  font-family: "Aribau";
  src: url("../public/Aribau/AribauGrotesk-Regular.woff2") format("woff2"),
    url("../public/Aribau/AribauGrotesk-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Aribau";
  src: url("../public/Aribau/AribauGrotesk-Bold.woff2") format("woff2"),
    url("../public/Aribau/AribauGrotesk-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Aribau";
  src: url("../public/Aribau/AribauGrotesk-Light.woff2") format("woff2"),
    url("../public/Aribau/AribauGrotesk-Light.woff") format("woff");
  font-weight: 300; /* Light */
  font-style: normal;
}

@font-face {
  font-family: "Aribau";
  src: url("../public/Aribau/AribauGrotesk-Light.woff2") format("woff2"),
    url("../public/Aribau/AribauGrotesk-Light.woff") format("woff");
  font-weight: 400; /* Light */
  font-style: normal;
}

@font-face {
  font-family: "Aribau";
  src: url("../public/Aribau/AribauGrotesk-Black.woff2") format("woff2"),
    url("../public/Aribau/AribauGrotesk-Black.woff") format("woff");
  font-weight: 900; /* Black */
  font-style: normal;
}

@font-face {
  font-family: "Aribau";
  src: url("../public/Aribau/AribauGrotesk-Italic.woff2") format("woff2"),
    url("../public/Aribau/AribauGrotesk-Italic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
}
